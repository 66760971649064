import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

const SoftwareUp = ({ contentModuleId }) => {

  const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutSoftwareUp {
                edges {
                    node {
                        id
                        heading
                        subheading
                        description {
                            description
                        }
                        ctaText
                        ctaUrl
                        image {
                            fluid(quality: 100) {
                                ...GatsbyContentfulFluid
                            }
                        }
                    }
                }
            }
        }
    `);

  const content = data.allContentfulLayoutSoftwareUp.edges.find(edge => edge.node.id === contentModuleId);

  return (
    <section className="softwareUp container section mx-auto">
      <div className="softwareUp__tagline">
        <div className="softwareUp__tagline-content-wrap">
          <h2 className="softwareUp__tagline-title" data-sal="fade">{content.node.heading}</h2>
          <p className="softwareUp__tagline-subtitle" data-sal="fade" data-sal-delay="100">{content.node.subheading}</p>
          <p className="softwareUp__tagline-text" data-sal="fade"
             data-sal-delay="200">{content.node.description.description}</p>
          <a href={content.node.ctaUrl}>
            <button className="btn btn--primary mt-8" data-sal="fade"
                    data-sal-delay="300">{content.node.ctaText}</button>
          </a>
        </div>
      </div>
      <div className="softwareUp__image">
        <img src={content.node.image.fluid.src} className="mx-auto" alt="SoftwareUp" data-sal="slide-right"
             data-sal-delay="400" data-sal-duration="500" />
      </div>
    </section>
  );
};

SoftwareUp.propTypes = {
  contentModuleId: PropTypes.string.isRequired
};

export default SoftwareUp;
