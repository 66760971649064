import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import MemberItem from "../components/memberItem";

const Team = ({ contentModuleId }) => {

  const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutTeam {
                edges {
                    node {
                        id
                        title
                        memberItem {
                          id
                          name
                          firstPosition
                          secondPosition
                          otherDetails {
                            otherDetails
                          }
                          ctaText
                          ctaUrl
                          image {
                            fluid (quality: 100) {
                              ...GatsbyContentfulFluid
                            }
                          }
                        }
                    }
                }
            }
        }
    `);

  const content = data.allContentfulLayoutTeam.edges.find(edge => edge.node.id === contentModuleId);

  return (
    <section id="team" className="team section bg-gray">
      <div className="container mx-auto">
        <div className="w-full">
          <h2 className="section__title text-center mb-16" data-sal="fade"
              data-sal-easing="ease-in-cubic">{content.node.title}</h2>
        </div>

        <div className="w-full">
          {
            content.node.memberItem.length > 0 &&
            <div className="card__items">
              {
                content.node.memberItem.map(memberItem => (
                  <MemberItem memberItem={memberItem} key={memberItem.id} />
                ))
              }
            </div>
          }
        </div>
      </div>
    </section>
  );
};

Team.propTypes = {
  contentModuleId: PropTypes.string.isRequired
};

export default Team;
