import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import LifeCycleItem from "components/lifeCycleItem";

const Work = ({ contentModuleId }) => {

  const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutWork {
                edges {
                    node {
                        id
                        heading
                        lifeCycle {
                            lifeCycle
                        }
                        lifeCycleItem {
                            id
                            title
                            icon
                        }
                        imageLifeCycle {
                            fluid(quality:100) {
                                ...GatsbyContentfulFluid
                            }
                        }
                    }
                }
            }
        }
    `);

  const content = data.allContentfulLayoutWork.edges.find(edge => edge.node.id === contentModuleId);

  return (
    <section id="work" className="work bg-gray">
      <div className="container section mx-auto">
        <div className="work__content">
          <h2 className="section__title" data-sal="fade"
              data-sal-easing="ease-in-cubic">{content.node.heading}</h2>
          <p data-sal="slide-up" data-sal-easing="ease-in-cubic"
             data-sal-delay="100">{content.node.lifeCycle.lifeCycle}</p>
          {
            content.node.lifeCycleItem.length > 0 &&
            <ul className="mt-10 md:ml-8">
              {
                content.node.lifeCycleItem.map(feature => (
                  <LifeCycleItem feature={feature} key={feature.id} />
                ))
              }
            </ul>
          }
        </div>
        <div className="work__image self-center">
          <div className="mx-auto work__image-wrap" data-sal="slide-up" data-sal-delay="200"
               data-sal-duration="500">
            <Img fluid={content.node.imageLifeCycle.fluid} />
          </div>
        </div>
      </div>
    </section>
  );
};

Work.propTypes = {
  contentModuleId: PropTypes.string.isRequired
};

export default Work;
