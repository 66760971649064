import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const CardItem = ({ cardItem }) => (
  <div className={`card__item`} data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
    <div className="card__item-content">
      <div className="mx-auto card__item-image" data-sal="slide-up" data-sal-delay="200"
           data-sal-duration="500">
        <Img fluid={cardItem.image.fluid} />
      </div>
      <p className="card__item-description"><span>{cardItem.description.description}</span></p>
    </div>
  </div>
);

CardItem.propTypes = {
  cardItem: PropTypes.object.isRequired
};


export default CardItem;
