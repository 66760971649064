import React from "react";

const LifeCycleItem = ({ feature }) => (
  <li className="mb-6">
    <div className="item" data-sal="slide-up" data-sal-easing="ease-in-cubic">
      <i className="item__icon material-icons text-primary">{feature.icon}</i>
      <div className="item__content self-center">
        <h3 className="item__title text-primary">{feature.title}</h3>
      </div>
    </div>
  </li>
);

export default LifeCycleItem;
