import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import ExpertiseItem from "../components/expertiseItem";

const Expertise = ({ contentModuleId }) => {

  const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutExpertise {
                edges {
                    node {
                        id
                        heading
                        expertise {
                            expertise
                        }
                        expertiseItem {
                            id
                            title
                            icon
                        }
                        imageExpertise {
                            fluid(quality:100) {
                                ...GatsbyContentfulFluid
                            }
                        }
                    }
                }
            }
        }
    `);

  const content = data.allContentfulLayoutExpertise.edges.find(edge => edge.node.id === contentModuleId);

  return (
    <section id="expertise" className="expertise">
      <div className="container section mx-auto">
        <div className="expertise__content">
          <h2 className="section__title" data-sal="fade"
              data-sal-easing="ease-in-cubic">{content.node.heading}</h2>
          <p data-sal="slide-up" data-sal-easing="ease-in-cubic"
             data-sal-delay="100">{content.node.expertise.expertise}</p>
          {
            content.node.expertiseItem.length > 0 &&
            <ul className="mt-10 md:ml-8">
              {
                content.node.expertiseItem.map(feature => (
                  <ExpertiseItem feature={feature} key={feature.id} />
                ))
              }
            </ul>
          }
        </div>
        <div className="expertise__image self-center">
          <div className="mx-auto expertise__image-wrap" data-sal="slide-up" data-sal-delay="200"
               data-sal-duration="500">
            <Img fluid={content.node.imageExpertise.fluid} />
          </div>
        </div>
      </div>
    </section>
  );
};

Expertise.propTypes = {
  contentModuleId: PropTypes.string.isRequired
};

export default Expertise;
