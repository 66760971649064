import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import CardItem from "../components/cardItem";

const Customers = ({ contentModuleId }) => {

  const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutCustomers {
                edges {
                    node {
                        id
                        title
                        customerItem {
                          id
                          title
                          description {
                            description
                          }
                          image {
                            fluid (quality: 100) {
                              ...GatsbyContentfulFluid
                            }
                          }
                        }
                    }
                }
            }
        }
    `);

  const content = data.allContentfulLayoutCustomers.edges.find(edge => edge.node.id === contentModuleId);

  return (
    <section id="customers" className="customer section bg-gray">
      <div className="container mx-auto">
        <h2 className="section__title text-center mb-16" data-sal="fade"
            data-sal-easing="ease-in-cubic">{content.node.title}</h2>
        {
          content.node.customerItem.length > 0 &&
          <div className="card__items">
            {
              content.node.customerItem.map(cardItem => (
                <CardItem cardItem={cardItem} key={cardItem.id} />
              ))
            }
          </div>
        }
      </div>
    </section>
  );
};

Customers.propTypes = {
  contentModuleId: PropTypes.string.isRequired
};

export default Customers;
