import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import AboutItem from "../components/aboutItem";

const About = ({ contentModuleId }) => {

  const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutAbout {
                edges {
                    node {
                      id
                      heading
                      aboutItems {
                        id
                        title
                        buttonText
                        buttonUrl
                        description {
                          description
                        }
                      }
                    }
                }
            }
        }
    `);

  const content = data.allContentfulLayoutAbout.edges.find(edge => edge.node.id === contentModuleId);

  return (

    <section id="about" className="about section bg-gray">
      <div className="container mx-auto">
        <h2 className="section__title text-center mb-16" data-sal="fade"
            data-sal-easing="ease-in-cubic">{content.node.heading}</h2>
        {
          content.node.aboutItems.length > 0 &&
          <div className="about__items">
            {
              content.node.aboutItems.map(aboutItem => (
                <AboutItem aboutItem={aboutItem} key={aboutItem.id} />
              ))
            }
          </div>
        }

      </div>
    </section>
  );
};

About.propTypes = {
  contentModuleId: PropTypes.string.isRequired
};

export default About;
