import React from "react";
import PropTypes from "prop-types";
import SoftwareUp from "./softwareUp";
import About from "sections/about";
import Expertise from "sections/expertise";
import Work from "sections/work";
import Services from "sections/services";
import Customers from "sections/customers";
import Testimonials from "sections/testimonials";
import Team from "sections/team";
import Contact from "sections/contact";
import PageNotFound from "sections/pageNotFound";

// Dynamically import or require sections inside the section folder
const components = {
  SoftwareUp,
  About,
  Expertise,
  Services,
  Work,
  Customers,
  Testimonials,
  Team,
  Contact,
  PageNotFound
};

const Section = ({ contentModuleId, type }) => {

  const component = type.split("Layout")[1];

  if (typeof components[component] === "undefined") {
    return "";
  }

  return React.createElement(components[component], {
    contentModuleId
  });
};

Section.prototype = {
  contentModuleId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default Section;
