import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const MemberItem = ({ memberItem }) => (
  <div className={`card__item`} data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
    <div className="card__item-content">
      <h3 className="card__item-title">{memberItem.name}</h3>
      <div className="mx-auto member__item-image" data-sal="slide-up" data-sal-delay="200"
           data-sal-duration="500">
        <Img className="member__item-image" fluid={memberItem.image.fluid} />
      </div>
      {
        (memberItem.firstPosition || memberItem.secondPosition) &&
        <div className="m-4">
          {
            memberItem.firstPosition &&
            <h1 className="item__title">{memberItem.firstPosition}</h1>
          }
          {
            memberItem.secondPosition &&
            <h1 className="item__title">{memberItem.secondPosition}</h1>
          }
        </div>
      }

      {/*{*/}
      {/*  memberItem.experience &&*/}
      {/*  <p className="card__item-description"><span>{memberItem.experience.experience}</span></p>*/}
      {/*}*/}
      {/*{*/}
      {/*  memberItem.technologies && memberItem.technologies.length > 0 &&*/}
      {/*  <ul>*/}
      {/*    {*/}
      {/*      memberItem.technologies.map(technology => (*/}
      {/*        <div className="self-center">*/}
      {/*          <h3 className="text-primary">{technology.title}</h3>*/}
      {/*        </div>*/}
      {/*      ))*/}
      {/*    }*/}
      {/*  </ul>*/}
      {/*}*/}
      {
        memberItem.otherDetails &&
        <p className="card__item-description"><span>{memberItem.otherDetails.otherDetails}</span></p>
      }
      {
        memberItem.ctaText && memberItem.ctaUrl &&
        <a href={memberItem.ctaUrl}>
          <button className="btn btn--primary mt-8" data-sal="fade" data-sal-delay="300">{memberItem.ctaText}</button>
        </a>
      }
    </div>
  </div>
);

MemberItem.propTypes = {
  memberItem: PropTypes.object.isRequired
};

export default MemberItem;
