import React from "react";
import PropTypes from "prop-types";

const AboutItem = ({ aboutItem }) => (
  <div className="aboutItem aboutItem--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
    <h3 className="aboutItem__title">{aboutItem.title}</h3>
    <p>{aboutItem.description.description}</p>
    <a href={aboutItem.buttonUrl}>
      <button className="btn btn--primary mt-8" data-sal="fade" data-sal-delay="300">{aboutItem.buttonText}</button>
    </a>
  </div>
);

AboutItem.propTypes = {
  aboutItem: PropTypes.object.isRequired
};

export default AboutItem;
